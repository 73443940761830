// テーマカラー設定
$gray-200: #eaeaea;
$gray-300: #d2d2d2;
$gray-400: #b8b8b8;
$gray-500: #9b9b9b;
$gray-600: #666666;
$gray-800: #333333;
$gray-900: #231815;
$red: #ad272d;
$primary: $gray-900;
$light: $gray-200;

// マージン
$spacers:();

@for $i from 0 through 25 {
  $spacers: map-merge($spacers, (#{$i * 4}:#{$i * 4}px));
}

// border-radius
$border-radius-lg: 16px;

/* Bootstrap を読み込む */
@import "~bootstrap/scss/bootstrap.scss";

// カスタムクラス
// --- html
body {
  font-weight: 500;
  line-height: 1.85;
  font-feature-settings: "palt"1;
  letter-spacing: .05em;
  z-index: 1;
}

body,
header,
main,
aside,
footer,
article,
section,
div,
li,
th,
td,
dt,
dd {
  &>*:first-child {
    margin-top: 0;
  }

  &>*:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
td,
dd {
  text-align: justify;
}

a {
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    border: 0;
  }

  img {
    border-style: none;
    outline: none;
    transition: opacity .35s, transform .35s;
  }

  &:hover {
    img {
      border: none;
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }

  &:active {
    img {
      outline: none;
      border: none;
    }
  }
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  width: auto;
}

video {
  max-width: 100%;
}

caption {
  color: inherit;
  caption-side: top;
}

ul,
ol {
  padding-left: 24px;

  li {
    line-height: 1.4;
  }

  &.fa-ul {
    margin-left: 24px;
  }
}

// --- wrapper
#wrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// --- footer
#area {
  &-header {
    z-index: 1000;
  }

  &-contents {}

  &-footer {
    margin-top: auto;
  }
}

// --- 背景
.bg {
  &-purple {
    background-color: #d7d7e4;
  }

  &-beige {
    background-color: rgba(#c2ac94, .2);
  }

  &-gray-300 {
    background-color: $gray-300;
  }

  &-gray-800 {
    background-color: $gray-800;
  }

  &-black {
    background-color: $black;
  }
}

// --- Border
.border-brown {
  border-color: #754C24 !important;
}

.border-gray-400 {
  border-color: $gray-400 !important;
}

.border-black {
  border-color: $black !important;
}

.border-width-4 {
  border-width: 4px !important;
}

.border-width-md-6 {
  @include media-breakpoint-up(md) {
    border-width: 6px !important;
  }
}

.border-width-md-10 {
  @include media-breakpoint-up(md) {
    border-width: 10px !important;
  }
}

// --- ドロワーメニュー
nav {
  width: 100%;
  height: 80px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: auto;
  }

  .drawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 80px;

    .navbar_brand {
      display: block;
      text-indent: -9999em;
      width: 28.4%;
      max-width: 160px;
      height: 100%;
      max-height: 72px;
      background-image: url(./img/SVG/logo.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .navbar_toggle {
      z-index: 9999;
      margin-left: 16px;

      @include media-breakpoint-up(md) {
        display: none;
      }

      &_icon {
        position: relative;
        display: block;
        height: 2px;
        width: 30px;
        background-color: $black;
        transition: ease .5s;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          margin-top: 8px;
        }
      }

      &.open {
        .navbar_toggle_icon {
          &:nth-child(1) {
            top: 5px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            top: -5px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  .menu {
    background-color: $gray-800;
    transform: translateX(100vw);
    transition: ease .5s;
    z-index: 9999;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
      border-top: 1px solid $black;
      background-color: transparent;
      transform: translateX(0);
      transition: none;
      margin-top: 12px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        display: flex;
        font-display: row;
        justify-content: space-between;
        align-items: center;
      }

      li {
        border-bottom: 1px solid $white;

        @include media-breakpoint-up(md) {
          border-bottom: none;
        }
      }

    }

    &.open {
      transform: translateX(0);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    a {
      display: block;
      color: $white;

      @include media-breakpoint-up(md) {
        color: $black;
      }
    }
  }
}

// --- ページトップに戻る
#toPageTop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9998;

  a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: darken($primary, 7.5%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: darken($primary, 5%);
    }

    i {
      margin-top: -2px;
    }
  }
}

// --- ガター
@for $i from 0 through 9 {
  .gtr-#{$i * 8} {
    margin: 0 calc((#{$i * 8}px / 2) * -1) !important;
  }

  .gtr-#{$i * 8}>[class*="col-"],
  .gtr-#{$i * 8}>.col {
    padding: 0 calc(#{$i * 8}px / 2) !important;
  }

  @media (min-width: 768px) {
    .gtr-md-#{$i * 8} {
      margin: 0 calc((#{$i * 8}px / 2) * -1) !important;
    }

    .gtr-md-#{$i * 8}>[class*="col-"],
    .gtr-md-#{$i * 8}>.col {
      padding: 0 calc(#{$i * 8}px / 2) !important;
    }
  }

  @media (min-width: 992px) {
    .gtr-lg-#{$i * 8} {
      margin: 0 calc((#{$i * 8}px / 2) * -1) !important;
    }

    .gtr-lg-#{$i * 8}>[class*="col-"],
    .gtr-lg-#{$i * 8}>.col {
      padding: 0 calc(#{$i * 8}px / 2) !important;
    }
  }

  @media (min-width: 1200px) {
    .gtr-xl-#{$i * 8} {
      margin: 0 calc((#{$i * 8}px / 2) * -1) !important;
    }

    .gtr-xl-#{$i * 8}>[class*="col-"],
    .gtr-xl-#{$i * 8}>.col {
      padding: 0 calc(#{$i * 8}px / 2) !important;
    }
  }
}

// --- アコーディオン
.accordion {
  .card {
    border-color: $gray-500 !important;

    &-header {
      position: relative;
      background-color: $gray-200;


      button {
        &:after {
          position: absolute;
          content: "\f068";
          font-family: "Font Awesome 5 Free";
          top: calc(50% - 0.5rem);
          right: 8px;
          display: inline-block;
          vertical-align: middle;
          font-size: 20px;
          transform: rotate(0);
          transition: all 0.3s;
          line-height: 1;
        }

        &.collapsed:after {
          content: "\f067";
        }

        &:focus {
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }
}

// --- Fonts, Title
@for $i from 8 through 64 {
  .fz-#{$i + 2} {
    font-size: #{$i + 2}px !important;
  }

  @media (min-width: 576px) {
    .fz-sm-#{$i + 2} {
      font-size: #{$i + 2}px !important;
    }
  }

  @media (min-width: 768px) {
    .fz-md-#{$i + 2} {
      font-size: #{$i + 2}px !important;
    }
  }

  @media (min-width: 992px) {
    .fz-lg-#{$i + 2} {
      font-size: #{$i + 2}px !important;
    }
  }

  @media (min-width: 1200px) {
    .fz-xl-#{$i + 2} {
      font-size: #{$i + 2}px !important;
    }
  }
}

// 余白
.px-0 {
  &.px-md-auto {
    @include media-breakpoint-up(md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

// --- アウター
.outer {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((70 / 99) * 100%);
  }

  >.inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

// --- 矢印
.arw-right {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid $white;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 50%;
    // right: calc(50% - 5.5em);
    right: 16px;
    transform: translateY(-50%);
  }
}

.arw-right-long {
  width: 65px;
  display: inline-block;
  position: relative;
  top: -4px;
  margin-left: 8px;
}

// --- 一部を隠して More ボタン表示
// https://copypet.jp/502/
.point-contents {
  position: relative;

  label {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 140px;
    /* グラデーションの高さ */
    cursor: pointer;
    text-align: center;
    /* 以下グラデーションは背景を自身のサイトに合わせて設定してください */
    background: linear-gradient(to bottom, rgba(250, 252, 252, 0) 0%, rgba($white, 1) 100%);
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &::after {
      line-height: 2.5rem;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 50%;
      width: 16rem;
      content: 'More';
      transform: translate(-50%, 0);
      letter-spacing: 0.05em;
      border: 1px solid;
    }
  }

  input {
    display: none;

    &:checked {
      &+label {
        background: inherit;

        &::after {
          content: 'Close';
        }
      }

      &~.point-contents-body {
        height: auto;
        padding-bottom: 56px;
        /* 閉じるボタンのbottomからの位置 */
        transition: all 0.5s;
      }
    }
  }

  &-body {
    overflow: hidden;
    height: 250px;
    /* 開く前に見えている部分の高さ */
    transition: all 0.5s;

    @include media-breakpoint-up(md) {
      height: auto;
    }
  }
}

// --- HOME
#home-movie {
  border-radius: .25rem;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 100%;
    background-color: rgba($black, .25);
    border-radius: .5rem;
  }

  video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: .5rem;
  }
}

#home-catch {
  img {
    width: 100px;

    @include media-breakpoint-up(md) {
      width: 128px;
    }
  }
}

#home-about {
  @include media-breakpoint-up(md) {
    background-color: #d7d7e4;
  }

  &-catch {
    margin-top: -36px;
  }

  &-img {
    margin-top: -16px;
    background-image: url(./img/home-about-img-sp.png);
    z-index: -1;

    @include media-breakpoint-up(md) {
      background-image: url(./img/home-about-img-pc.png);
      margin-top: 0;
      z-index: 1;
    }

    &:before {
      padding-top: calc((249 / 187) * 100%);

      @include media-breakpoint-up(md) {
        padding-top: calc((409 / 414) * 100%);
      }
    }
  }

  .border-brown {
    border-width: 2px !important;
    padding: 16px;

    &::after {
      content: "";
      width: 16px;
      height: 16px;
      margin-top: -5px;
      border-top: solid 2px #754c24;
      border-right: solid 2px #754c24;
      position: absolute;
      top: calc(100% - 2px);
      left: 50%;
      transform: translateX(-50%) rotate(135deg);
      z-index: 1;
      background-color: $white;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: -7px;
        top: 50%;
        left: 100%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}

#home-uservoice {
  &-contents {
    max-height: 400px;
    overflow-y: auto;

    article {
      &+article {
        margin-top: 16px;

        @include media-breakpoint-up(lg) {
          margin-top: 40px;
        }
      }
    }
  }
}

#home-faq,
#faq-contents {
  .accordion>.card {
    border: none;
    border-radius: 0;

    .card-header {
      background-color: transparent;
      border-bottom: 2px solid $black;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      margin-bottom: 0;

      .btn {
        color: $black;
        text-align: left;
        background-color: transparent;
        border: none;
        text-indent: -1.65em;
        padding-left: 1.6em;
        position: relative;

        @include media-breakpoint-up(lg) {
          font-size: 26px;
          text-indent: -1.5em;
        }

        &::before {
          content: 'Q. ';
          font-size: 22px;

          @include media-breakpoint-up(lg) {
            font-size: 32px;
          }
        }

        &::after {
          top: 50%;
          color: $gray-600;
          right: 0;
          transform: rotate(0) translateY(-50%);
        }
      }
    }
  }
}

#service-reach {
  .position-absolute {
    background-color: $black;
    color: $white;
    font-weight: 700;
    line-height: 1;
    right: -12px;
    bottom: -12px;
    z-index: 1;
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}